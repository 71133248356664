.faqContainer {
  margin-top: 8rem;
  margin-bottom: 5rem;
}
.faqHeader {
  font-family: "Volkhov Regular";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 5.2rem;
  text-align: center;
  color: #14183e;
}
.faqContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
}
.questionItem {
  width: 70%;
  margin: 2rem 0rem;
}
.questionContainer {
  display: flex;
  align-items: flex-start;
}
.questionOuter {
  display: flex;
}
.numbering {
  font-family: "Inter";
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.7rem;
  letter-spacing: 0.25px;
  color: #3c3c43;
  padding: 0rem 2rem;
  margin-top: -0.4rem;
}
.questionHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.questionHeader h2 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.6rem;
  color: black;
  width: 90%;
}
.expandButton {
  position: relative;
  font-weight: 400;
  font-size: 1.1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid #464646;
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.expandButton.active {
  background-color: black;
}
.expandButton:hover {
  cursor: pointer;
}
.plusHorizontal {
  position: absolute;
  width: 1.2rem;
  height: 0.2rem;
  background: #464646;
  border-radius: 0.3rem;
  transition: all 0.5s ease;
  transform: rotate(0deg);
}
.plus {
  position: absolute;
  width: 1.2rem;
  height: 0.2rem;
  background: #464646;
  border-radius: 0.3rem;
  transition: all 0.5s ease;
  transform: rotate(-90deg);
}
.plusHorizontal.active {
  transform: rotate(45deg);
  background-color: white;
}
.plus.active {
  transform: rotate(-45deg);
  background-color: white;
}
#answerContent {
  margin-top: 3rem;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: opacity 200ms linear, height 200ms linear;
}
#answerContent p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #3c3c43;
}
.fadedBorder1 {
  margin-top: -1rem;
  border-bottom: 1.4px solid #dddddd;
}
.fadedBorder2 {
  margin-top: 3rem;
  border-bottom: 1.4px solid #dddddd;
}
/* ---------------------------------------for width upto 768px */
@media screen and (max-width: 768px) {
  .questionItem {
    width: 90%;
  }
  #answerContent p {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .questionHeader h2 {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}
/* ---------------------------------------for width upto 600px */
@media screen and (max-width: 600px) {
  .faqHeader {
    font-family: "Volkhov Regular";
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.8rem;
    text-align: center;
    color: #14183e;
  }
  .questionOuter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .numbering {
    font-family: "Inter";
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4rem;
    letter-spacing: 0.25px;
    color: rgba(60, 60, 67, 0.5);
    padding: 0rem 0rem;
    margin-top: 0rem;
  }
  .expandButton {
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    background-color: #f3f5f6;
    padding: 1.5rem;
  }
  .questionHeader {
    margin-top: 1rem;
  }
}
/* ------------------------------------for width upto 500px */
@media screen and (max-width: 500px) {
  .faqHeader {
    font-size: 2.8rem;
    line-height: 4.6rem;
  }
}
/* ------------------------------------for width upto 400px */
@media screen and (max-width: 400px) {
  .faqHeader {
    font-size: 3.2rem;
    line-height: 5rem;
  }
  .numbering {
    font-size: 3.2rem;
    line-height: 4.1rem;
  }
  .questionHeader h2 {
    font-size: 2.3rem;
    line-height: 2.9rem;
  }
}
