@font-face {
    font-family: "Avenir Black";
    src: url("../../assets/fonts/Avenir-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Bebas Neue";
    src: url("../../assets/fonts/BebasNeue-Regular.ttf") format("truetype");
}

.partner-with-us-page{
    overflow: hidden;
    background: #FAFAFA;
}

.bg-container{
    margin-bottom: -4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
}

.form-modal{
    position: relative;
    width: 720px;
    height: 520px;
    z-index: 20;
    background: #FAFAFA;
    border-radius: 30px;
    padding: 2rem 7rem 6rem 7rem;
}

.form-modal h1 {
    text-align: center;
    font-size: 56px;
    margin-bottom: 2rem;
}

.button-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container button{
    border: none;
    width: 164px;
    height: 50px;
    color: white;
    font-size: 1.8rem;
    border-radius: 6px;
    background: linear-gradient(275deg, #62049F 0.75%, #B11CFF 100%);
    cursor: pointer;
}

.form-name{
    display: flex;
    gap: 6rem;
}

.form-name input{
    background: #D9D9D9;
    font-size: 18px;
    font-family: "Avenir Regular";
    width: 100%;
    height: 52px;
    border: none;
    padding: 0 2rem;
}

.modal-contact-detail{
    font-size: 18px;
    font-family: "Avenir Regular";
    width: 100%;
    background: #D9D9D9;
    height: 52px;
    border: none;
    padding: 0 2rem;
    margin-top: 4rem;
}

.close-btn{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.over-img-text{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.over-img-text h1{
    color: #FFF;
    font-family: "Bebas Neue";
    font-size: 128px;
    font-weight: 400;
    margin-bottom: -34px;
}

.over-img-text button{
    margin-top: 3rem;
    width: 200px;
    height: 54px;
    color: white;
    cursor: pointer;
    font-family: "Avenir Regular";
    font-size: 26px;
    font-weight: 900;
    border-radius: 5px;
    border: 1px solid #000;
    background: #BB68E7;
}

.over-img-text p{
    color: #FFF;
    font-family: "Avenir Regular";
    font-size: 24px;
}

.bg-container img{
    width: 100%;
    object-fit: cover;
    min-height: 34rem;
    max-height: 50rem;
}

.banner{
    height: 16rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #7219B1 0%, #BB68E7 100.86%);
}

.banner p{
    font-size: 22px;
    font-family: "Avenir Regular";
    color: white;
}

.store-icon-container img{
    height: 3.4rem;
}

.store-icon-container{
    display: grid;
    gap: 2rem;
}

.partner-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 14rem;
}

.partner-container h1{
    color: #000;
    text-align: center;
    font-family: "Volkhov Regular";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.partner-detail{
    display: grid;
    gap: 19rem;
    grid-template-columns: 1fr 1fr;
}

.partner-detail-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partner-detail-gap{
    display: grid;
    gap: 4rem; 
}

.partner-detail-box img{
    height: 60px;
    width: 60px;
}

.partner-detail-box h4{
    color: #848484;
    text-align: center;
    font-family: "Avenir Regular";
    font-size: 18px;
    font-weight: 900;
    padding-top: 1.2rem;
}

.benefits-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin: 10rem;
    margin-bottom: 0;
}

.benefits-detail{
    display: grid;
    gap: 4rem;
    grid-template-columns: 1fr 1fr;
}

.benefits-container h1{
    color: #000;
    text-align: center;
    font-family: "Volkhov Regular";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.benefits-detail-box {
    width: 500px;
    height: 230px;
    padding-bottom: 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.benefits-detail-box img {
    height: 50px;
    width: 50px;
    transition: all 0.3s ease;
}

.benefits-detail-box h3 {
    font-size: 18px;
    transition: all 0.3s ease;
}

.benefits-detail-box.hovered img{
    height: 67px;
    width: 67px;
}

.benefits-detail-box.hovered{
    padding-bottom: .4rem;
}

.benefits-detail-box.hovered h3{
    font-size: 14px;
}

.benefits-detail-gap{
    display: grid;
    gap: 3rem; 
}

.benefits-detail-box img{
    height: 97px;
    width: 97px;
}

.benefits-detail-box p{
    font-family: "Avenir Regular";
}

/* Form Container */

.svg-container{
    position: relative;
    padding-top: 6rem;
    height: 1620px;
}

.partner-form-container{
    position: relative;
    z-index: 20;
    margin: 10rem 14%;
    height: 1240px;
    background: white;
    border-radius: 20px ;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.form-container{
    margin: 0 9rem;
    padding-top: 5rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-container h1{
    text-align: center;
    font-family: "Avenir Black";
    font-size: 76px;
    font-weight: 900;
}

.form-details{
    display: flex;
    flex-direction: column;
    gap: 56px;
    margin-top: 4rem;
    width: 90%;
}

.detail-name{
    display: flex;
    gap: 6rem;
}

.detail-name input{
    background: #D9D9D9;
    width: 100%;
    height: 52px;
}

.contact-detail{
    height: 52px;
    background: #D9D9D9;
}

.form-details input, .contact-detail {
    padding: 0 2rem;
    color: #000;
    font-family: "Avenir Regular";
    font-size: 16px;
    border: none;
}

.img-container{
    position: relative;
    margin-top: 2rem;
    width: 96%;
    margin: auto;
    height: 48%;
    border-radius: 15px;
    background: linear-gradient(180deg, #FFF 0%, #D4C1FF, #D4C1FF, #D4C1FF 100%);
}

.phone-img-container{
    display: flex;
    justify-content: center;
}

.phone-side-img{
    padding-top: 4rem;
    height: 36rem;
}

.phone-center-img{
    height: 36rem;
    margin: 0 -4px;
}

.overlay-phone{
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 15px 15px;
    background: #D4C1FF;
    box-shadow: 0px -4px 9px 0px rgba(0, 0, 0, 0.25);
    height: 20rem;
    width: 100%;
}

@media screen and (max-width: 1300px) {
    .svg-container{
        height: 1602px;
    }

    .partner-form-container{
        height: 1290px;
    }
}

@media screen and (max-width: 1100px) {
    .svg-container{
        height: 1402px;
    }

    .partner-form-container{
        height: 1090px;
    }

    .img-container{
        height: 45%;
    }

    .benefits-detail-box {
        width: 400px;
        height: 200px;
    }

}

@media screen and (max-width: 1030px) {
    .svg-container{
        height: 1602px;
    }

    .img-container{
        height: 43%;
    }

    .partner-form-container{
        height: 1100px;
    }

    .benefits-detail-box {
        width: 300px;
        height: 180px;
    }
}

@media screen and (max-width: 860px) {

    .bg-container img{
        min-height: 56rem;
        max-height: 90rem;
    }

    .over-img-text h1{
        font-size: 96px;
        margin-bottom: -16px;
    }

    .over-img-text p{
        font-size: 26px;
    }

    .over-img-text button{
        margin-top: 5rem;
        width: 220px;
        height: 7rem;
    }


    .svg-container{
        height: 1360px;
    }

    .partner-form-container{
        margin: 14rem 10%;
        height: 1050px;
    }

    .partner-detail{
        gap: 6rem;
    }

    .benefits-detail{
        gap: 6rem;
    }
}

@media screen and (max-width: 745px) {
    .svg-container{
        height: 1380px;
    }

    .partner-form-container{
        margin: 14rem 4%;
        height: 1080px;
    }

    .partner-detail{
        gap: 3rem;
    }

    .benefits-detail{
        gap: 3rem;
    }

    .img-container{
        height: 45.4%;
    }
}

@media screen and (max-width: 700px) {

    .form-container h1{
        font-size: 66px;
    }

    .benefits-container h1{
        font-size: 34px;
    }

    .benefits-detail-box{
        width: 260px;
        height: 161px;
    }
}

@media screen and (max-width: 650px) {
    .bg-container img{
        min-height: 72rem;
        max-height: 90rem;
    }

    .over-img-text h1{
        font-size: 69px;
        margin-bottom: -12px;
    }

    .over-img-text p{
        font-size: 22px;
    }

    .over-img-text button{
        margin-top: 6rem;
        width: 210px;
        height: 6.6rem;
    }


    .partner-form-container{
        margin: 14rem 4%;
        height: 1070px;
    }
}

@media screen and (max-width: 620px) {
    
    .partner-with-us-page{
        background: white;
    }

    .partner-container h1{
        font-size: 34px;
    }

    .partner-detail{
        display: flex;
        flex-direction: column;
        gap: 2.6rem;
    }

    .partner-detail h4{
        font-size: 16px;
    }

    .benefits-container h1{
        font-size: 26px;
    }

    .benefits-detail-gap{
        gap: 8rem;
    }

    .benefits-detail{
        display: flex;
        flex-direction: column;
        gap: 8rem;
    }

    .benefits-detail-box{
        box-shadow: none;
        width: 35rem;
        gap: 1rem;
    }

    .benefits-detail-box h3{
        font-size: 16px;
    }

    .benefits-detail-box p{
        text-align: center;
        font-size: 14px;
    }

    /* Form Container */

    .svg-container{
        height: 1120px;
    }

    .partner-container{
        margin-top: 10rem;
    }

    .partner-form-container{
        margin: 14rem 0;
        height: 872px;
        border-radius: 0;
        box-shadow: none;
    }

    .form-container h1{
        font-size: 40px;
    }

    .form-details{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 4rem;
        width: 120%;
    }

    .detail-name{
        display: grid;
        gap: 20px;
    }
    
    .img-container, .overlay-phone{
        width: 100%;
        height: 48%;
        border-radius: 0;
    }
    
    .phone-side-img{
        padding-top: 3.6rem;
        height: 32rem;
    }
    
    .phone-center-img{
        height: 30rem;
    }
}

/* mobile screen start point */

@media screen and (max-width: 480px) {

    .bg-container img{
        min-height: 86rem;
        max-height: 90rem;
    }

    .over-img-text h1{
        font-size: 58px;
        margin-bottom: -16px;
    }

    .over-img-text p{
        font-size: 20px;
    }

    .over-img-text button{
        margin-top: 5rem;
        width: 194.23px;
    }

    .svg-container{
        height: 1198px;
    }

    .form-container button{
        font-size: 2.4rem;
    }

    .overlay-phone{
        height: 16rem;
    }

    .partner-form-container{
        margin: 10rem 0;
        height: 720px;
        border-radius: 0;
        box-shadow: none;
    }

    .benefits-container{
        margin: 8rem 6%;
    }

    .banner{
        flex-direction: column;
        height: 20rem;
        justify-content: space-around;
    }

    .banner p{
        font-size: 14px;
    }

    .store-icon-container img{
        height: 3rem;
    }
    
    .store-icon-container{
        display: grid;
        gap: 1rem;
    }

    .phone-side-img{
        padding-top: 3rem;
        height: 28rem;
    }
    
    .phone-center-img{
        height: 26rem;
    }
}

@media screen and (max-width: 420px) {
    .svg-container{
        height: 1054px;
    }

    .partner-form-container{
        margin: 14rem 0;
        height: 660px;
        border-radius: 0;
        box-shadow: none;
    }
}

@media screen and (max-width: 400px) {
    .svg-container{
        height: 936px;
    }

    .partner-form-container{
        margin: 14rem 0;
        height: 574px;
        border-radius: 0;
        box-shadow: none;
    }

    .form-container h1 {
        font-size: 4.6rem;
    }
}

@media screen and (max-width: 370px) {
    .svg-container{
        height: 932px;
    }

    .partner-form-container{
        margin: 14rem 0;
        height: 564px;
        border-radius: 0;
        box-shadow: none;
    }
}

@media screen and (max-width: 300px) {

    .bg-container img{
        min-height: 80rem;
        max-height: 90rem;
    }

    .over-img-text h1{
        font-size: 44px;
        margin-bottom: -8px;
    }

    .over-img-text p{
        font-size: 14px;
    }

    .over-img-text button{
        margin-top: 4rem;
        width: 180px;
    }


    .svg-container{
        height: 856px;
    }

    .partner-container h1{
        font-size: 24px;
    }

    .partner-detail-box h4{
        font-size: 13px;
    }

    .benefits-container{
        margin: 6rem 2rem;
        gap: 1rem;
    }

    .benefits-container h1{
        font-size: 20px;
    }

    .benefits-detail-box p{
        font-size: 10px;
        padding: 0 3rem;
    }

    .form-container{
        margin: 0 4rem;
    }

    .form-container h1{
        font-size: 28px;
    }

    .detail-name input{
        height: 46px;
    }
    
    .contact-detail{
        height: 46px;
        background: #D9D9D9;
    }
    
    .partner-form-container{
        margin: 14rem 0;
        height: 520px;
        border-radius: 0;
        box-shadow: none;
    }

    .overlay-phone{
        height: 14rem;
    }

    .phone-side-img{
        padding-top: 2.6rem;
        height: 23.4rem;
    }
    
    .phone-center-img{
        height: 23rem;
    }
    
    .img-container{
        margin-top: 2rem;
    }
}
