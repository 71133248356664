* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "SF PRO DISPLAY BOLD";
  src: url("./assets/fonts/SFPRODISPLAYREGULAR.OTF") format("opentype");
}
@font-face {
  font-family: "Noto-Sans-Bold";
  src: url("./assets/fonts/NotoSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Noto-Sans-SemiBold";
  src: url("./assets/fonts/NotoSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Noto-Sans-Medium";
  src: url("./assets/fonts/NotoSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Brittany Signature";
  src: url("./assets/fonts/Brittany-Signature.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir Regular";
  src: url("./assets/fonts/AvenirRegular.ttf") format("truetype");
}
@font-face {
  font-family: "Volkhov Regular";
  src: url("./assets/fonts/Volkhov-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Medium";
  src: url("./assets/fonts/Poppins\ Medium\ 500.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Roboto Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Judson Regular";
  src: url("./assets/fonts/Judson-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Varien";
  src: url("./assets/fonts/Varien.ttf") format("truetype");
}
