.policyContainer {
  padding: 0rem 2rem;
  padding-bottom: 2rem;
  background-color: white;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
}
.policyHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem;
  line-height: 5rem;
  border-bottom: 0.5px solid grey;
}
.policyParagraph {
  font-size: 1.4rem;
  margin: 1rem 0rem;
}
.sectionHeading {
  font-size: 1.5rem;
}
.informationSection ul {
  padding-left: 3.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.policyLinkText {
  color: #009c9c;
  font-size: 1.3rem;
}
.serviceSection ul {
  padding-left: 3rem;
}
.serviceSection li {
  font-size: 1.3rem;
}
