.modalInnerContainer {
  padding: 2rem 3rem;
  height: 75%;
  width: 65%;
  max-height: 600px;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: white;
  border-radius: 71px;
  z-index: 0;
  overflow: hidden;
}
.modalInnerContainer.active {
  animation: downToCenter 0.5s ease 0s 1 forwards;
}
@keyframes downToCenter {
  0% {
    top: 100%;
  }
  100% {
    top: 0%;
  }
}
.cancelContainer {
  position: absolute;
  top: 3rem;
  right: 4.4rem;
}
.cancelContainer img {
  width: 5.8rem;
  height: 5.8rem;
  object-fit: contain;
  cursor: pointer;
}
.modalInnerContainer h2 {
  color: var(--3rd, #14183e);
  text-align: center;
  font-family: "Volkhov Regular";
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 3rem;
}
.qrContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-top: 5rem;
}
.qrCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qrCard img {
  width: 15rem;
  height: 15rem;
  object-fit: contain;
}
.qrCard p {
  color: #424242;
  text-align: center;
  font-family: "Avenir Regular";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100.5%;
  margin-top: 1rem;
}
.halfCircle {
  position: absolute;
  border-radius: 882px;
  background: #bb68e7;
  width: 40rem;
  height: 40rem;
  bottom: -20rem;
  left: -20rem;
  z-index: -1;
}
/* ------------------------------------------ for width upto 1000px */
@media (max-width: 1000px) {
  .modalInnerContainer {
    height: 70%;
    width: 70%;
  }
}
/* ------------------------------------------ for width upto 900px */
@media (max-width: 900px) {
  .modalInnerContainer {
    width: 80%;
  }
}
/* ------------------------------------------ for width upto 768px */
@media (max-width: 768px) {
  .modalInnerContainer {
    display: none;
  }
}
