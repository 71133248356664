.comingSoonContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comingSoonContainer img {
  width: 100%;
  scale: 0.8;
  object-fit: contain;
}
@media screen and (max-width: 720px) {
  .comingSoonContainer img {
    scale: 1.2;
  }
}
