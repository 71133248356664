.gymCityContainer {
  background: transparent
    linear-gradient(110deg, rgba(62, 1, 84, 1) 0%, rgba(185, 4, 252, 1) 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  padding: 1rem 0rem;
  justify-content: space-evenly;
  align-items: center;
}

.gymCityContainer h2 {
  font-family: "Avenir Regular";
  font-weight: 800;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  color: white;
  cursor: pointer;
}
.topGymText {
  font-family: "Volkhov Regular";
  font-weight: 700;
  font-size: 3rem;
  line-height: 4.4rem;
  color: #14183e;
  padding-left: 13.6rem;
  margin-top: 5.7rem;
}
.selectedCityContainer {
  padding: 3rem 0rem;
  padding-left: 13.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gymCard {
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  overflow: hidden;
  margin-right: 4rem;
  cursor: pointer;
}
.sampleImage {
  width: 24rem;
  height: 20rem;
  object-fit: cover;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.infoContainer {
  padding: 1.5rem 0rem;
}
.infoLeft,
.infoRight {
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
  align-items: center;
  margin-bottom: 1rem;
}
.gymCard:hover .sampleImage {
  transform: scale(1.2);
  transform-origin: 0% 100%;
  transition: transform 0.5s ease;
}
.navigateInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infoLeft h2 {
  font-family: "Poppins Medium";
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: #5e6282;
}
.infoLeft h3 {
  font-family: "Poppins Regular";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5e6282;
}
.navigateInfo img {
  margin-top: 0.3rem;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.3rem;
  object-fit: contain;
}
.navigateInfo h2 {
  font-family: "Poppins Regular";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5e6282;
}
.infoRight h3 {
  font-family: "Avenir Regular";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: center;
  color: #9452b8;
  text-decoration: underline;
  cursor: pointer;
}
.moreText p {
  font-family: "Avenir Regular";
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  text-decoration: underline;
  color: #9452b8;
  cursor: pointer;
}
.playButton {
  display: none;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}
.playButton img {
  width: 1.1rem;
  height: 1.3rem;
  object-fit: contain;
}
.rightNavigateButton img {
  transform: rotate(180deg);
}
.centerCityMode {
  display: none;
}
/* ------------------------------for width upto 900px */
@media screen and (max-width: 900px) {
  .topGymText {
    padding-left: 7.6rem;
  }
  .selectedCityContainer {
    padding-left: 7.6rem;
  }
}
/* ------------------------------for width upto 800px */
@media screen and (max-width: 800px) {
  .topGymText {
    padding-left: 4rem;
  }
  .selectedCityContainer {
    padding-left: 4rem;
  }
}
/* ------------------------------for width upto 768px */
@media screen and (max-width: 768px) {
  .gymCityContainer h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .gymCityContainer {
    padding: 2rem 0rem;
  }
  .topGymText {
    display: none;
  }
  .selectedCityContainer {
    padding: 3rem 0rem;
    padding-left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .gymCard {
    margin-right: 0rem;
    margin-top: 4rem;
    cursor: pointer;
  }
  .moreText p {
    font-size: 2rem;
    line-height: 2.6rem;
    margin-top: 4rem;
  }
  .leftCity,
  .rightCity,
  .middleCity {
    display: none;
  }
  .playButton {
    display: flex;
  }
  .centerCityMode {
    display: flex;
  }
}
/* -----------------------------------for width upto 400px */
@media screen and (max-width: 400px) {
  .gymCityContainer h2 {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }
  .moreText p {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
  .sampleImage {
    width: 30rem;
    height: 26rem;
    object-fit: cover;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .infoContainer {
    padding: 3rem 0rem;
  }
  .infoLeft h2 {
    font-size: 1.8rem;
    line-height: 2.22rem;
  }
  .infoLeft h3 {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
  .navigateInfo img {
    margin-top: 0.3rem;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
    object-fit: contain;
  }
  .navigateInfo h2 {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
  .infoRight h3 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
