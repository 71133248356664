.blogContainer {
  width: 100%;
  overflow: hidden;
}

#blogCoverImg {
  width: 100%;
  position: relative;

  p {
    position: absolute;
    width: 100vw;
    top: 30%;
    display: flex;
    justify-content: center;
    font-size: 96px;
    font-weight: 700;
    color: white;

    @media screen and (max-width: 699px) {
      font-size: 24px;
    }
  }

  img {
    width: 100%;
    overflow: hidden;
  }
}

.blogHeaderContainer {
  background: #c0c0c0de;
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 5%;

  ul {
    font-size: 24px;
    font-family: "Avenir Regular";
    font-weight: 800;
    color: white;

    @media screen and (max-width: 699px) {
      font-size: 16px;
      padding: 5px 20px;
      border-radius: 15px;
      font-weight: 400;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 699px) {
    overflow-x: scroll;
    padding-left: 140px;
    background: white;
  }
}

.blogSubheading {
  display: flex;
  justify-content: center;
  color: #14183e;
  font-family: "Volkhov Regular";
  text-transform: capitalize;
  font-size: 52px;
  margin-top: 4%;
  margin-bottom: 4%;

  @media screen and (max-width: 699px) {
    font-size: 24px;
  }
}

.topSingleArticlesWrapper {
  display: flex;
  margin: 5% 10% 0 10%;
  justify-content: center;

  @media screen and (max-width: 699px) {
    margin: 10% 0 5% 0;
  }
}

.topArticlesWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 2% 10%;
  gap: 4%;
  justify-content: center;

  @media screen and (max-width: 699px) {
    width: 86vw;
    margin: 4% 6%;
  }
}

.topArticleContainer {
  align-items: center;
  display: flex;
  gap: 4%;
  margin-bottom: 2%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  background: #fafafa;
  border-radius: 20px;
  width: 37%;
  position: relative;
  // flex-direction: row;

  @media screen and (max-width: 699px) {
    width: 90vw;
    flex-direction: column;
    gap: 1%;
  }

  &:hover {
    cursor: pointer;
  }

  .oneRow {
    width: 100%;
  }

  &.topSingleLiner {
    width: 78%;
  }

  .articleCoverPage {
    margin: 10px 0 10px 8px;
    border-radius: 10px;
    width: 96vw;
    overflow: hidden;
    border-radius: 21px;
    background-color: #eeeeeeaa;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 20rem;
      object-fit: cover;
      transition: all 0.3s ease;
    }

    @media screen and (max-width: 699px) {
      margin: 5% 0;
      width: 76vw;
      height: 74vw;

      img {
        width: 100%;
        height: 44rem;
      }
    }

    &.singleLiner {
      width: 10vw;
      height: 18vh;
      margin: 10px 0 10px 14px;

      img {
        width: 100%;
        height: 20rem;
        object-fit: contain;
        transition: all 0.3s ease;
      }

      @media screen and (max-width: 699px) {
        margin: 2.4% 0 2.4% 2.4%;
        width: 12vh;
        height: 12vh;
      }
    }

    &.topSingleLiner {
      width: 42vw;
      height: 42vh;
      margin-left: 14px;

      img {
        width: 100%;
        height: 24rem;
        object-fit: cover;
        transition: all 0.3s ease;
      }

      @media screen and (max-width: 699px) {
        margin-left: 0;
        width: 76vw;
        height: 58vh;

        img {
          width: 100%;
          height: 42rem;
        }
      }
    }
  }

  h6 {
    font-size: 11px;
    color: #848484;
    font-family: "Avenir Regular";
    font-weight: 500;
    line-height: 22px;
  }

  h2 {
    font-size: 16px;
    padding: 3px 0;
    width: 90%;
    padding: 6px 0 6px 0;
    font-family: "Avenir Black";
    color: #2c2c2c;
  }

  .categoryName {
    text-decoration: underline;
  }

  .redirectionLineIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;

    @media screen and (max-width: 699px) {
      z-index: -1; // to hidden the link arrow
    }
  }

  .redirectionIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;

    @media screen and (max-width: 699px) {
      top: 90%;
      right: 14px;
      width: 32px;
      height: 32px;
    }
  }

  .articleContentInfo {
    padding: 6px 0;

    @media screen and (max-width: 699px) {
      padding: 0 5%;
    }
  }

  &.singleLiner {
    width: 80%;

    h6 {
      font-size: 13px;
    }

    h2 {
      padding: 10px 0 8px 0;
    }

    @media screen and (max-width: 699px) {
      width: 90vw;
      flex-direction: row;
      padding-bottom: 0;

      h6 {
        font-size: 8px;
      }

      h2 {
        width: 280px;
        font-size: 12px;
        padding: 2px 0 4px 0;
      }
    }

    @media screen and (max-width: 480px) {
      h2 {
        width: 190px;
        font-size: 12px;
      }
    }

    @media screen and (max-width: 300px) {
      h2 {
        width: 120px;
        font-size: 10.4px;
      }
    }
  }

  @media screen and (max-width: 699px) {
    padding-bottom: 20px;
  }

  &.topSingleLiner {
    h6 {
      font-size: 16px;
    }

    h2 {
      font-size: 24px;
      padding: 24px 0 12px 0;
    }

    p {
      padding-bottom: 8px;
      color: #848484;
      font-family: "Avenir Regular";
      font-size: 15px;
      font-weight: 500;
    }

    @media screen and (max-width: 699px) {
      width: 86vw;
      flex-direction: column;
      gap: 1%;

      h2 {
        font-size: 16px;
        padding: 10px 0 12px 0;
      }

      h6 {
        font-size: 13px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

.topArticleContainer:hover .articleCoverPage img {
  transform: scale(1.2);
  transform-origin: 50% 50%;
}
