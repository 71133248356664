.about-us-page {
  overflow: hidden;
  background-color: white;
  margin-top: 140px;
}

.about-content-container {
  padding: 2rem 8rem 0 8rem;
  margin-bottom: 8rem;
}

.about-content-container h1 {
  color: #000;
  text-align: center;
  font-family: "Volkhov Regular";
  font-size: 46px;
  font-weight: 700;
  text-transform: capitalize;
}

.about-content-container p {
  color: #000;
  font-family: "Avenir Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.wave-container {
  position: relative;
  height: 960px;
  width: 100%;
}

.wave-svg-BG {
  position: absolute;
}

.first-paragraph {
  padding-top: 40px;
}

.other-paragraph {
  padding-top: 26px;
}

.about-setup h1 {
  color: #000;
  text-align: center;
  font-family: "Volkhov Regular";
  font-size: 46px;
  font-weight: 700;
  text-transform: capitalize;
}

.wave-content {
  position: absolute;
  padding: 0 12rem;
}

.wave-content div {
  margin-top: 98px;
  color: #000;
  font-family: "Avenir Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
}

.wave-content div p {
  margin-top: 26px;
  color: #000;
  font-family: "Avenir Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  .wave-content {
    position: absolute;
    padding: 0 11rem;
  }

  .wave-content div {
    margin-top: 80px;
  }

  .wave-content div p {
    margin-top: 30px;
    font-size: 17px;
  }
}

@media screen and (max-width: 800px) {
  .about-content-container {
    padding: 2rem 5rem 0 5rem;
    margin-bottom: 6rem;
  }

  .about-content-container h1 {
    font-size: 30px;
  }

  .about-content-container p {
    font-size: 15px;
  }

  .first-paragraph {
    padding-top: 40px;
  }

  .other-paragraph {
    padding-top: 26px;
  }

  .about-setup h1 {
    font-size: 24px;
  }

  .wave-content {
    position: absolute;
    padding: 0 10rem;
  }

  .wave-content div {
    margin-top: 86px;
  }

  .wave-content div p {
    margin-top: 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 700px) {
  .about-setup h1 {
    font-size: 24px;
  }

  .wave-content {
    position: absolute;
    padding: 0 7rem;
  }

  .wave-content div {
    margin-top: 64px;
    font-size: 18px;
  }

  .wave-content div p {
    margin-top: 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .about-setup h1 {
    font-size: 24px;
  }

  .wave-content {
    position: absolute;
    padding: 0 5rem;
  }

  .wave-content div {
    margin-top: 58px;
    font-size: 17px;
  }

  .wave-content div p {
    margin-top: 20px;
    font-size: 15px;
  }
}

/* mobile screen start point */

@media screen and (max-width: 480px) {
  .about-content-container {
    padding: 2rem 5rem 0 5rem;
    margin-bottom: 10rem;
  }

  .about-content-container h1 {
    font-size: 24px;
  }

  .about-content-container p {
    font-size: 16px;
  }

  .wave-container {
    height: 1120px;
  }

  .wave-svg-BG {
    height: 1100px;
    width: 414px;
  }

  .first-paragraph {
    padding-top: 40px;
  }

  .other-paragraph {
    padding-top: 26px;
  }

  .about-setup h1 {
    text-align: center;
    font-size: 24px;
  }

  .wave-content {
    position: absolute;
    padding: 0 5rem;
  }

  .wave-content div {
    margin-top: 72px;
  }

  .wave-content div h2 {
    font-size: 22px;
    text-align: center;
  }

  .wave-content div p {
    margin-top: 24px;
    font-size: 16px;
  }
}

@media screen and (max-width: 300px) {
  .about-setup h1 {
    font-size: 23px;
  }

  .about-content-container p {
    font-size: 15px;
  }

  .wave-content div {
    margin-top: 58px;
  }

  .wave-content div p {
    margin-top: 20px;
    font-size: 14px;
  }
}