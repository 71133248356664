.perksTableContainer {
  margin: 3rem 0rem;
}
.perksTableContainer h2 {
  font-family: "Volkhov Regular";
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 5rem;
  text-align: center;
  color: #14183e;
}
.tableSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0rem;
}
.criteriaName {
  font-family: "Inter";
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #252430;
}
table {
  border-collapse: collapse;
}
th {
  padding: 2rem 3.5rem;
  font-family: "Roboto Regular";
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #252430;
  border-right: 1px solid #e6e9f5;
  text-wrap: wrap;
}
td {
  padding: 1rem;
  border: 1px solid #e6e9f5;
  border-left: none;
}
/* ---------------------------------------------- for width upto 700px */
@media screen and (max-width: 700px) {
  .perksTableContainer h2 {
    font-size: 2.7rem;
    line-height: 4.3rem;
  }
  th {
    padding: 2.3rem 1.5rem;
    font-family: "Roboto Regular";
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.6rem;
    color: #252430;
    border-right: 1px solid #e6e9f5;
  }
}
/* ---------------------------------------------- for width upto 600px */
@media screen and (max-width: 600px) {
  .perksTableContainer h2 {
    font-size: 2.5rem;
    line-height: 4.1rem;
  }
  th {
    padding: 2.5rem 1rem;
    font-family: "Roboto Regular";
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #252430;
    border-right: 1px solid #e6e9f5;
  }
  .criteriaName {
    font-family: "Inter";
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2.1rem;
    color: #252430;
  }
}
/* ---------------------------------------------- for width upto 500px */
@media screen and (max-width: 500px) {
  td {
    padding: 2rem 0.1rem;
  }
}
/* ---------------------------------------------- for width upto 300px */
@media screen and (max-width: 200px) {
  .perksTableContainer h2 {
    font-size: 3rem;
    line-height: 4.6rem;
  }
}
