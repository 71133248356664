@font-face {
  font-family: "Volkhov Regular";
  src: url("../../assets/fonts/Volkhov-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Bold";
  src: url("../../assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../assets/fonts/Poppins-Bold.ttf") format("truetype");
}

.header-text h2 {
  text-align: center;
  font-size: 68px;
  color: #bb68e7;
  line-height: 4.6rem;
  font-family: "Avenir Regular";
  opacity: 0;
}

.header-text p {
  margin: auto;
  width: 56rem;
  text-align: center;
  font-size: 18px;
  line-height: 2.4rem;
  color: #4d4d4d;
  padding: 20px 40px 0 40px;
  font-family: "Avenir Regular";
  opacity: 0;
}

.animate.text-state {
  animation: headerText 2s ease-in-out;
  opacity: 1;
}

.animate.text-state {
  animation: headerParaText 0.6s ease-in-out;
  opacity: 1;
}

@keyframes headerText {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  100% {
    opacity: 1;
  }
}

@keyframes headerParaText {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  100% {
    opacity: 1;
  }
}

/* Order Button */

.orderBtn {
  display: flex;
  justify-content: center;
  background: linear-gradient(200deg, #9600ff, #b650ff);
  width: 14rem;
  height: 4rem;
  margin: auto;
  align-items: center;
  border-radius: 6px;
  margin-top: 18px;
  margin-bottom: 70px;
  border: none;
  opacity: 0;
  cursor: pointer;
}

.orderBtn.btn-animate {
  animation: button 0.6s ease-in;
  opacity: 1;
}

@keyframes button {
  0% {
    opacity: 0;
    transform: translateY(60%);
  }
}

.orderBtn h1 {
  font-family: "Avenir Regular";
  color: white;
  text-align: center;
}

.orderBtn:hover {
  background: white;
  border: 1.4px solid #9600ff;
}

.orderBtn:hover h1,
.orderBtn h1:hover {
  color: #9600ff;
}

/* Animation Circle */

.container {
  position: relative;
  color: white;
  width: 120%;
  height: 350px;
  transform: translate(-8.4%);
  display: flex;
  justify-content: center;
  z-index: -10;
}

.half-sphere {
  width: 100%;
  height: 220vh;
  border-radius: 50%;
  background: #000;
  animation: sphereGrow 2s ease-in-out;
  z-index: -10;
}

.half-sphere.BG-shade {
  background: linear-gradient(180deg, #7404b9 -14%, #000 20%);
  animation: animate 3s ease-out;
}

@keyframes sphereGrow {
  0% {
    transform: translateY(20%);
    z-index: -10;
    background: #000;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate {
  0% {
    background: linear-gradient(180deg, #7404b9 -100%, #000 0%);
  }
  20% {
    background: linear-gradient(180deg, #7404b9 -80%, #000 10%);
  }
  50% {
    background: linear-gradient(180deg, #7404b9 -40%, #000 14%);
  }
  80% {
    background: linear-gradient(180deg, #7404b9 -20%, #000 18%);
  }
  100% {
    background: linear-gradient(180deg, #7404b9 -14%, #000 20%);
  }
}

.jar-container {
  position: absolute;
  top: -7rem;
  opacity: 0;
}

.jar-container.show-jar {
  animation: jar 1.4s ease-out;
  opacity: 1;
}

.center-jar {
  height: 360px;
}

.jar-detail-text {
  position: absolute;
  font-size: 28px;
  top: 9rem;
  right: 40rem;
  opacity: 0;
}

.jar-drink-text {
  position: absolute;
  font-family: "Avenir Regular";
  text-align: center;
  line-height: 4.6rem;
  top: 8rem;
  left: 38rem;
  opacity: 0;
}

.jar-detail-text.show-text,
.jar-drink-text.show-text {
  animation: jarText 0.2s ease-out;
  opacity: 1;
}

.jar-drink-text h3 {
  font-size: 24px;
}

.jar-drink-text h1 {
  font-size: 68px;
  font-weight: 900;
}

@keyframes jar {
  0% {
    opacity: 0;
    transform: translateY(18%);
  }
}

@keyframes jarText {
  0% {
    opacity: 0;
    transform: translateY(18%);
  }
}

/* Purple Banner */

.banner-BG {
  height: 136px;
  background: #8f00ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.banner-BG h1 {
  position: relative;
  font-size: 46px;
  padding: 0 4rem;
  font-family: "Poppins Bold";
}

.start-quote,
.end-quote {
  opacity: 0.4;
}

.start-quote {
  position: absolute;
  left: 0;
  padding-left: 1.8rem;
}

.end-quote {
  position: absolute;
  bottom: -1.4px;
  padding-left: 0.7rem;
  right: -1;
}

/* About Product Detail */

.review-BG {
  background: linear-gradient(180deg, #000 20.39%, #512c00 102.3%);
  height: auto;
  padding: 8rem 2rem 0 2rem;
}

.review-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12rem;
  justify-content: center;
  color: white;
  max-width: calc(100%);
}

.review {
  color: white;
  width: 400px;
}

.review h1 {
  font-size: 32px;
  font-family: "Avenir Regular";
}

.review p {
  font-size: 14px;
  margin-top: 1.2rem;
  word-spacing: 0.03rem;
  letter-spacing: 0.12rem;
  font-family: "Avenir Regular";
}

/* Cosumption (After Image) */

.usage-BG {
  background: #000;
  height: auto;
  padding-top: 3rem;
  padding-bottom: 4rem;
  margin-top: -10.4rem;
}

.usage-BG h2 {
  font-size: 3.8rem;
  text-align: center;
  font-family: "Volkhov Regular";
  color: white;
}

.usage-BG p {
  font-size: 1.4rem;
  text-align: center;
  color: white;
}

.consumtion-container {
  display: flex;
  flex-direction: row;
  gap: 6rem;
  margin-top: 3rem;
  padding: 0 2rem;
  justify-content: center;
}

.consumtion-box {
  background-color: white;
  width: 380px;
  padding: 2rem 3rem 3rem 3rem;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.consumtion-box div {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3.6rem;
  margin-bottom: 10px;
}

.consumtion-box h3 {
  text-align: center;
  font-family: "Volkhov Regular";
  color: #753d1c;
}

/* BG Image and text */

.protein-img {
  position: absolute;
  height: 2rem;
}

.protein-container {
  width: 100%;
  position: relative;
  top: -10rem;
  z-index: -4;
}

.protein-background {
  width: 100%;
  object-fit: cover;
  min-height: 40rem;
  max-height: 50rem;
}

.shade-overlay {
  background: rgba(0, 0, 0, 0.26);
  position: absolute;
  height: 50rem;
  width: 100%;
}

.supplement-header {
  position: absolute;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  top: 11rem;
  left: 4rem;
  width: 44rem;
  color: white;
  z-index: 4;
}

.supplement-header h1 {
  font-size: 58px;
  font-family: "Inter Bold";
}

.supplement,
.supplement-type {
  color: white;
  font-family: "Inter Bold";
  position: absolute;
  right: 6rem;
  width: 420px;
  display: flex;
  gap: 8rem;
  z-index: 4;
}

.supplement-type {
  top: 25rem;
}

.supplement {
  top: 9rem;
}

.supplement div h1,
.supplement-type div h1 {
  font-size: 58px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: -6px;
}

.supplement div p,
.supplement-type div p {
  font-size: 13px;
  font-weight: 500;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Video Container */

.video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8rem auto;
  padding: 1px;
  border-radius: 10px;
  background: #d9d9d9;
  width: 70%;
}

.video-box {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* box-shadow: inset 0 10px 29px #FFFFFF; */
}

/* Customer Image and Count */

.customer-detail {
  color: white;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customer-title {
  text-align: center;
  font-family: "Volkhov Regular";
  word-spacing: 0.2rem;
  letter-spacing: 0.16rem;
  font-size: 50px;
  font-weight: 500;
  text-transform: capitalize;
}

.customer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 28rem;
  justify-items: center;
  gap: 8rem;
  align-items: center;
  margin-top: 4rem;
}

.customer-image-container {
  display: flex;
  gap: 6px;
}

.customer-image-container div {
  display: grid;
  gap: 6px;
}

.customer-img {
  max-width: 100%;
  max-height: 100%;
}

.customer-count {
  display: flex;
  align-items: center;
  width: 130%;
  justify-content: center;
  border-radius: 20px;
  height: 98%;
  background: linear-gradient(113deg, #5c168e 2.07%, #9452b8 98.38%);
}

.customer-count h1 {
  font-size: 4rem;
  text-align: center;
  font-family: "Volkhov Regular";
}

/* Footer */

.footer-store-container {
  position: relative;
  background: #ebeaea;
  box-shadow: 3px 4px 2px 6px rgba(0, 0, 0, 0.25);
  padding: 6rem 0 2.2rem 0;
}

.footerOption {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footerOption ul {
  width: 52%;
  display: flex;
  justify-content: space-between;
}

.footerOption li {
  padding-top: 2rem;
  font-size: 24px;
  font-family: "Avenir Regular";
  font-weight: 900;
  list-style: none;
}

.footerOption a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.footerOption a:hover {
  color: #7404b9;
}

.reserve-right-container {
  text-align: center;
  padding: 5rem 0 5rem 0;
  color: #000;
}

.reserve-right-text {
  font-size: 20px;
  color: #5e6282;
}

.app-download {
  display: grid;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 140px;
}

.download-logo {
  height: 3rem;
}

.social-logo {
  height: 4.9rem;
}

.discover-app-text {
  text-align: center;
  padding-bottom: 1rem;
  font-size: 20px;
}

@media screen and (max-width: 1100px) {
  .jar-detail-text {
    top: 11rem;
    right: 32rem;
  }

  .jar-drink-text {
    top: 10rem;
    left: 28rem;
  }
}

@media screen and (max-width: 980px) {
  .header-text h2 {
    font-size: 52px;
  }

  .header-text p {
    width: 50rem;
  }

  .container {
    height: 300px;
  }

  .half-sphere {
    height: 180vh;
  }

  .jar-container {
    top: -8rem;
  }

  .center-jar {
    height: 340px;
  }

  .jar-drink-text.show-text,
  .jar-detail-text.show-text {
    opacity: 0;
  }

  .video-container {
    border-radius: 20px;
  }

  .video-box {
    border-radius: 20px;
  }

  .consumtion-container {
    gap: 2.4rem;
  }

  .review-container {
    gap: 4rem;
  }

  .review {
    color: white;
    max-width: 300px;
  }

  .supplement-header h1 {
    font-size: 40px;
    width: 30rem;
  }

  .supplement,
  .supplement-type {
    right: -2rem;
  }

  .supplement-type {
    top: 22rem;
  }

  .supplement div h1,
  .supplement-type div h1 {
    font-size: 50px;
  }

  .supplement div p,
  .supplement-type div p {
    font-size: 11px;
  }

  .protein-img {
    position: relative;
    height: 326px;
  }

  .protein-img-container {
    top: -20rem;
  }

  .customer-count {
    height: 84%;
  }

  .customer-detail {
    padding-bottom: 60px;
  }

  /* Footer */

  .footerOption {
    width: 56%;
  }

  .footerOption ul {
    margin-top: 3rem;
    flex-direction: column;
  }

  .app-download {
    top: 220px;
    right: 60px;
  }
}

@media screen and (max-width: 820px) {
  .container {
    height: 240px;
  }

  .half-sphere {
    height: 86vh;
  }

  .customer-count {
    width: 98%;
    text-align: center;
  }

  .jar-container {
    top: -8rem;
  }

  .center-jar {
    height: 300px;
  }

  .usage-BG {
    padding-bottom: 0;
  }

  .orderBtn h1 {
    font-size: 2rem;
  }

  .customer-container {
    height: 20rem;
    gap: 4rem;
  }

  .customer-count {
    height: 100%;
  }

  .customer-count h1 {
    font-size: 26px;
    text-align: center;
  }

  .supplement,
  .supplement-type {
    right: -10rem;
  }

  .supplement-header h1 {
    font-size: 36px;
  }
}

@media (max-width: 700px) {
  .usage-BG h2 {
    font-size: 34px;
  }

  .review {
    color: white;
    width: 320px;
  }

  .customer-count {
    height: 90%;
  }

  .customer-title {
    font-size: 24px;
  }

  .center-jar {
    height: 280px;
  }

  /* Footer */

  .footerOption li {
    font-size: 18px;
  }

  .reserve-right-text {
    font-size: 16px;
  }

  .app-download {
    right: 20px;
  }
}

@media (max-width: 670px) {
  .header-text h2 {
    font-size: 32px;
  }

  .header-text p {
    width: 46rem;
  }

  .container {
    height: 160px;
  }

  .half-sphere {
    height: 50vh;
  }

  .banner-BG {
    padding-top: 4rem;
    background: #000;
    margin-bottom: -4px;
  }

  .banner-BG h1 {
    font-size: 34px;
  }

  .center-jar {
    height: 240px;
  }

  .customer-container {
    margin-top: 1rem;
    gap: 1rem;
  }

  .customer-count {
    height: 76%;
    width: 100%;
  }

  .customer-count h1 {
    font-size: 20px;
  }

  .supplement,
  .supplement-type {
    right: -19rem;
    gap: 3rem;
  }

  .supplement {
    top: 10rem;
  }

  .supplement-type {
    top: 19rem;
  }

  .supplement div h1,
  .supplement-type div h1 {
    font-size: 36px;
  }

  .supplement div p,
  .supplement-type div p {
    font-size: 8px;
  }

  .supplement-header {
    left: 3rem;
  }

  .supplement-header h1 {
    font-size: 30px;
  }

  .download-logo {
    height: 2.6rem;
  }

  .social-logo {
    height: 4.4rem;
  }

  .discover-app-text {
    font-size: 16px;
  }

  .video-container {
    width: 90%;
  }
}

@media screen and (max-width: 560px) {
  .supplement,
  .supplement-type {
    right: -22rem;
    gap: 3rem;
  }

  .supplement {
    top: 11rem;
  }

  .supplement-type {
    top: 18rem;
  }

  .supplement div h1,
  .supplement-type div h1 {
    font-size: 32px;
  }

  .supplement div p,
  .supplement-type div p {
    font-size: 7px;
  }

  .supplement-header {
    left: 3rem;
  }

  .supplement-header h1 {
    font-size: 28px;
  }
}

@media screen and (max-width: 480px) {
  .header-text h2 {
    font-size: 36px;
  }

  .header-text p {
    width: 42rem;
  }

  .jar-container {
    top: -8rem;
  }

  .container {
    height: 150px;
  }

  .banner-BG h1 {
    font-size: 36px;
    line-height: 4.8rem;
    margin-bottom: 4.6rem;
  }

  .protein-background {
    min-height: 100rem;
  }

  .shade-overlay {
    height: 100%;
  }

  .supplement-header {
    top: 10rem;
    left: 6rem;
  }

  .supplement-header h1 {
    text-align: center;
    width: 40rem;
    line-height: 6rem;
    font-size: 40px;
    font-family: "Avenir Regular";
    font-weight: 900;
  }

  .supplement,
  .supplement-type {
    right: -18rem;
    gap: 4rem;
  }

  .supplement {
    flex-direction: column;
    gap: 2rem;
    top: 25rem;
  }

  .supplement-type {
    flex-direction: column;
    gap: 2rem;
    top: 54rem;
  }

  .supplement div h1,
  .supplement-type div h1 {
    font-size: 80px;
  }

  .supplement div p,
  .supplement-type div p {
    line-height: 10px;
    font-size: 18px;
  }

  .jar-container {
    top: -10rem;
  }

  .center-jar {
    height: 230px;
  }

  .consumtion-container {
    flex-wrap: wrap;
    gap: 3rem;
  }

  .consumtion-box {
    padding: 5rem 5rem;
    margin: 0 2rem;
    line-height: 2rem;
  }

  .customer-detail {
    padding-bottom: 40px;
  }

  /* for mobile device screen */

  .mobile-customer-count {
    border-radius: 20px;
    height: 100%;
    background: linear-gradient(113deg, #5c168e 2.07%, #9452b8 98.38%);
    margin: 0 1.6rem;
  }

  .mobile-customer-count h1 {
    font-size: 32px;
    text-align: center;
    font-family: "Volkhov Regular";
    font-weight: 500;
  }

  .mobile-customer-img {
    height: 16.9rem;
  }

  .mobile-image-container {
    display: grid;
    grid-template-rows: 1fr 1.4fr 1fr;
    gap: 1rem;
    padding: 4rem 0 6rem 0;
  }

  .mobile-image-container div {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: center;
  }

  /* Footer */

  .footerOption a {
    font-size: 1rem;
  }

  .footerOption ul {
    width: 70%;
  }

  .footerOption li {
    padding: 0;
  }

  .download-logo {
    height: 2.2rem;
  }

  .social-logo {
    height: 4.2rem;
  }

  .discover-app-text {
    font-size: 12px;
  }

  .app-download {
    top: 200px;
  }
}

@media screen and (max-width: 420px) {
  .jar-container {
    top: -8rem;
  }

  .center-jar {
    height: 22rem;
  }

  .orderBtn {
    width: 22rem;
    height: 7rem;
  }

  .orderBtn h1 {
    font-size: 3.2rem;
  }

  .supplement-type,
  .supplement {
    right: -12rem;
  }

  .supplement {
    flex-direction: column;
    gap: 5.4rem;
    top: 26rem;
  }

  .supplement-type {
    flex-direction: column;
    gap: 5.4rem;
    top: 60rem;
  }

  .supplement div h1,
  .supplement-type div h1 {
    font-size: 9rem;
  }

  .supplement div p,
  .supplement-type div p {
    font-size: 20px;
  }

  .supplement-header {
    left: 1.2rem;
  }

  .supplement-header h1 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 400px) {
  .header-text h2 {
    font-size: 26px;
    width: 26rem;
    line-height: 4rem;
    margin: auto;
  }

  .header-text p {
    font-size: 12px;
    color: black;
    padding: 12px 20px 6px 20px;
  }

  .jar-container {
    top: -10rem;
  }

  .center-jar {
    height: 26rem;
  }

  .supplement-type,
  .supplement {
    right: -18.6rem;
  }

  .supplement-header {
    left: 6.4rem;
    top: 11.6rem;
  }

  .supplement div p,
  .supplement-type div p {
    font-size: 16px;
  }

  .mobile-customer-count {
    margin: 0 2rem;
  }

  .mobile-customer-count h1 {
    font-size: 30px;
  }

  .mobile-customer-img {
    height: 20.4rem;
  }

  .mobile-customer-count h1 {
    font-size: 36px;
  }
}

@media screen and (max-width: 380px) {
  .header-text p {
    width: 36rem;
  }

  .customer-title {
    font-size: 24px;
  }

  .supplement-header {
    left: 2.6rem;
  }

  .supplement-type,
  .supplement {
    right: -21.2rem;
  }

  .supplement div p,
  .supplement-type div p {
    font-size: 16px;
  }

  .center-jar {
    height: 200px;
  }

  .consumtion-box {
    padding: 4rem 2rem;
  }

  .mobile-customer-count {
    margin: 0 2rem;
  }

  .mobile-customer-count h1 {
    font-size: 30px;
  }

  .mobile-customer-img {
    height: 19.4rem;
  }

  .footerOption li {
    line-height: 2.8rem;
  }

  .footerOption a {
    font-size: 1.2rem;
  }

  .app-download {
    top: 180px;
  }

  .reserve-right-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 300px) {
  .mobile-customer-count {
    margin: 0 1.6rem;
  }

  .mobile-customer-img {
    height: 14.4rem;
  }

  .supplement-type,
  .supplement {
    right: -26.6rem;
  }

  .supplement {
    top: 26rem;
  }

  .supplement-type {
    top: 58rem;
  }

  .supplement div h1,
  .supplement-type div h1 {
    font-size: 8.4rem;
  }

  .supplement-header {
    left: 3.6rem;
  }

  .supplement-header h1 {
    font-size: 30px;
    width: 28rem;
  }

  .app-download {
    top: 190px;
  }

  .download-logo {
    height: 1.9rem;
  }

  .social-logo {
    height: 3.8rem;
  }
}
