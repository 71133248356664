.shareContainer {
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom));;
  background: linear-gradient(#6448ff, #35016a);
  padding: 0rem 2rem;
}
.header {
  height: 20%;
  padding: 1.5rem 0rem;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ownerLogo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ownerLogo img {
  height: 7rem;
  object-fit: contain;
}
.monthPlanContainer {
  height: 10%;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.monthPlan {
  width: 100%;
  max-width: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.monthPlan h3 {
  font-size: 1.6rem;
  color: #d2a5ff;
  cursor: pointer;
}
.planUnderline {
  position: relative;
  width: 100%;
  max-width: 500px;
  border: 1px solid #9986ff;
  margin-top: 1rem;
}
.animatedLine {
  position: absolute;
  width: 50%;
  max-width: 250px;
  border: 2px solid white;
  top: -0.1rem;
  left: 0;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
}
.animatedLine.right {
  left: 50%;
}
.shareFieldContainer {
  height: 70%;
  width: 100%;
  max-width: 520px;
  padding: 1.2rem 0rem;
}
.yearlyContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yearlyContainer h3 {
  font-size: 2.4rem;
  color: #d2a5ff;
}
.yearlyInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.yearlyInput h3 {
  margin-right: 1rem;
}
.yearlyContainer input {
  border-radius: 10px;
  padding: 1.5rem;
}
.breakdownContainer {
  padding: 1rem 0rem;
  display: flex;
  justify-content: space-between;
}
.breakdownContainer h3 {
  color: #d2a5ff;
  font-size: 1.9rem;
}
.totalContainer {
  padding: 1rem 0rem;
  display: flex;
  justify-content: space-between;
}
.totalContainer h3 {
  color: #d2a5ff;
  font-size: 2.4rem;
}
.userPaymentContainer {
  padding: 1rem 0rem;
  display: flex;
  justify-content: space-between;
}
.userPerDay {
  display: flex;
  flex-direction: column;
}
.userPaymentContainer h3 {
  color: #d2a5ff;
  font-size: 2.2rem;
  line-height: 2rem;
}
.userPaymentContainer p {
  text-align: left;
  color: #d2a5ff;
  font-size: 1.4rem;
  margin-left: 0.5rem;
}
.userPaymentContainer span {
  text-align: left;
  color: #d2a5ff;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  line-height: 1.1rem;
}
.ownerFeeContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}
.ownerFeeContainer h3 {
  color: #d2a5ff;
  font-size: 2.6rem;
  line-height: 2rem;
}
.ownerFeeContainer p {
  color: #d2a5ff;
  font-size: 1.5rem;
  line-height: 2.2rem;
}
.ownerFeeContainer h2 {
  color: #d2a5ff;
  font-size: 4rem;
  line-height: 4.5rem;
}
@media (max-width: 768px) {
  .yearlyContainer {
    padding: 1.5rem 0rem;
  }
  .breakdownContainer {
    padding: 1.5rem 0rem;
  }
  .totalContainer {
    padding: 1.5rem 0rem;
  }
  .userPaymentContainer {
    padding: 1.5rem 0rem;
  }
  .userPaymentContainer p {
    padding: 0.7rem 0rem;
  }
  .ownerFeeContainer p {
    padding: 0.7rem 0rem;
  }
  .ownerFeeContainer {
    padding-top: 2rem;
  }
  .yearlyContainer input {
    width: 80%;
    padding: 1rem;
  }
}
@media (max-width: 400px) {
  .header {
    height: 15%;
  }
}
