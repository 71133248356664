.headerContainer {
  width: 100%;
  position: relative;
  height: 100vh;
  background: url("../../../assets/homeBackgroundImage.webp") center center /
    cover no-repeat;
}
.headerContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(185, 4, 252, 0.45),
    rgba(75, 0, 195, 0.45)
  );
  mix-blend-mode: multiply;
  opacity: 0.85;
}

.mainLogoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainLogo {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainLogo img {
  height: 10.5rem;
  object-fit: contain;
  max-width: 60rem;
}
.downloadButton {
  text-decoration: none;
  margin-top: 7.5rem;
  color: #ffffff;
  text-align: center;
  font: normal normal 600 1.5rem Noto-Sans-SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  background: transparent linear-gradient(108deg, #b904fc 0%, #4b00c3 100%) 0%
    0% no-repeat padding-box;
  border-radius: 24px;
  padding: 1rem 3rem;
  cursor: pointer;
  opacity: 0.7;
}
.downloadButton:hover {
  opacity: 1;
  font-size: 1.6rem;
}
.scrollContainer {
  position: absolute;
  bottom: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scrollDownButton {
  color: #ffffff;
  font: normal normal bold 16px/26px Noto-Sans-Bold;
  letter-spacing: -0.08px;
  color: #ffffff;
  animation: bounce 1s infinite;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.scrollDownButton img {
  margin-right: 1.5rem;
  width: 2rem;
  height: 2rem;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.navbarContainer {
  position: relative;
  height: 14%;
  width: 100%;
}
.navbar {
  height: 100%;
  width: inherit;
  position: relative;
  display: flex;
  align-items: center;
}
.navLogo {
  position: absolute;
  left: -4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  padding: 1rem 0rem;
}
.navLogo h2 {
  position: absolute;
  color: #ffffff;
  font-size: 1.7rem;
  top: 0rem;
  letter-spacing: 0.3rem;
}
.navLogo p {
  position: absolute;
  color: #ffffff;
  bottom: 1.2rem;
  font-size: 0.9rem;
  letter-spacing: 0.3rem;
  opacity: 0.7;
}
.navLogo img {
  width: 100%;
  opacity: 0.5;
  height: 3.8rem;
}
.navItems {
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}
.navItems ul {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  list-style: none;
}
.navItems a {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  font: normal normal 600 1.5rem Noto-Sans-SemiBold;
  letter-spacing: 0px;
}
.navItems li {
  opacity: 0.7;
}
.navItems li:hover {
  opacity: 1;
  font-size: 1.6rem;
}
.dropDownNavbar {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
}
.menuIconContainer {
  margin-top: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  width: 9rem;
  height: 3.5rem;
  background: linear-gradient(to right, #b904fc, #5500c8, #4b00c3);
  display: flex;
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.menuIcon {
  position: absolute;
  object-fit: contain;
  width: 4.5rem;
  top: 0;
  margin: auto;
}
.dropDownMenu {
  width: 0%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: saturate(180%) blur(21px);
  backdrop-filter: saturate(180%) blur(21px);
  border-bottom-left-radius: 35px;
  border-top-left-radius: 35px;
  padding: 3rem 0rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  transition: width 0.1s ease-in-out;
  overflow: hidden;
}
.dropDownMenuIos {
  width: 0%;
  background: transparent
    linear-gradient(3deg, rgba(185, 4, 252, 0.9) 0%, rgb(30, 0, 41, 1) 100%) 0%
    0% no-repeat padding-box;
  border-bottom-left-radius: 35px;
  border-top-left-radius: 35px;
  padding: 3rem 0rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  transition: width 0.1s ease-in-out;
  overflow: hidden;
}
.dropDownMenuIos li {
  list-style: none;
  padding: 3.5rem 0rem;
  padding-left: 8rem;
}
.dropDownMenuIos a {
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
  font: normal normal 600 2.5rem Noto-Sans-Medium;
}
.dropDownMenuIos a:hover {
  font-size: 2.6rem;
  opacity: 1;
}

.dropDownMenu li {
  list-style: none;
  padding: 3.5rem 0rem;
  padding-left: 8rem;
}
.dropDownMenu a {
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
  font: normal normal 600 2.5rem Noto-Sans-Medium;
}
.dropDownMenu a:hover {
  font-size: 2.6rem;
  opacity: 1;
}
.crossButtonContainer {
  width: 100%;
  padding: 0rem 1rem;
  display: flex;
  justify-content: flex-end;
}
.crossButton {
  width: 9rem;
  height: 3.5rem;
  background: linear-gradient(to right, #b904fc, #5500c8, #4b00c3);
  display: block;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}
.crossButton img {
  object-fit: contain;
  width: 2.5rem;
}
/* ------------------------------------statistics styles */

.statisticsContainer {
  background: transparent
    linear-gradient(110deg, rgba(62, 1, 84, 1) 0%, rgba(185, 4, 252, 1) 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  padding: 2rem 0rem;
  justify-content: space-around;
  align-items: center;
}
.counterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.counterContainer h3 {
  color: #ffffff;
  font: normal normal bold 1.8rem Noto-Sans-Bold;
  letter-spacing: 0px;
}
.counter {
  color: #ffffff;
  font: normal normal bold 2.5rem Noto-Sans-Bold;
  letter-spacing: 0px;
}
/* ---------------------------------------style benefit component */
.benefitsContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.benefitBackground {
  width: 100%;
  object-fit: cover;
  min-height: 40rem;
  max-height: 50rem;
}
.benefitText {
  width: inherit;
  position: absolute;
  top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.benefitText h2 {
  font: normal normal bold 3rem Noto-Sans-Bold;
  letter-spacing: -0.24px;
  color: #ffffff;
  text-align: center;
}
.benefitText p {
  text-align: center;
  font: normal normal normal 1.6rem/3rem Noto-Sans-Medium;
  letter-spacing: 0px;
  color: #ffffff;
  width: 65%;
  margin-top: 4rem;
}
.perksContainer {
  position: absolute;
  bottom: 0;
  width: 85%;
  display: flex;
  justify-content: space-around;
  padding: 3.5rem 0rem;
  background: transparent linear-gradient(#000000 0%, #5f0080 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: inset 0px 0px 34px #ffffff29;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(44px);
  -webkit-backdrop-filter: blur(44px);
}
.perkOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.perkOption h3 {
  font: normal normal bold 2rem Noto-Sans-Bold;
  letter-spacing: -0.16px;
  color: #ffffff;
}

.benefitIcon0 {
  position: absolute;
  object-fit: contain;
  width: 3.3rem;
}
.benefitIcon1 {
  position: absolute;
  width: 3rem;
  object-fit: contain;
}
.benefitIcon2 {
  position: absolute;
  width: 3rem;
  object-fit: contain;
}
.perkImage {
  background: transparent linear-gradient(180deg, #b904fc 0%, #4b00c3 100%) 0%
    0% no-repeat padding-box;
  border-radius: 50%;
  margin-right: 2rem;
  width: 5rem;
  height: 5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* -----------------------------------------------style feature component */

.featureContainer {
  background: #171b29 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
}
.perVisitContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-right: 6%;
}
.perVisitContainer h2 {
  font: normal normal bold 3rem Noto-Sans-Bold;
  letter-spacing: -0.26px;
  color: #ffffff;
}
.perVisitContainer img {
  width: 5rem;
  height: 3.5rem;
  margin-right: 2.4rem;
}
.perVisitText {
  width: 100%;
  padding-left: 6%;
  padding-right: 6%;
  font: normal normal medium Noto-Sans-Medium;
  font-size: 1.8rem;
  letter-spacing: -0.17px;
  color: #ffffff;
  margin-top: 2.5rem;
}
.appFlow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.appFlowContainer {
  display: none;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}
.diagramFlowMobile {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.diagramContainer {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  height: 100%;
  padding: 0rem 3.5rem;
}
.diagramContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem 0rem;
}
.textFlowMobile {
  height: 100%;
  display: none;
  grid-template-rows: 1fr 1fr 1fr;
  text-align: center;
  padding: 0rem 1rem;
}
.textFlowContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.diagramFlowMobile span {
  color: #ffffff;
  font: normal normal 1.5rem Noto-Sans-Medium;
  opacity: 0.8;
  margin-bottom: 1rem;
}
.textFlowMobile h3 {
  font: normal normal bold 2.3rem Noto-Sans-Medium;
  letter-spacing: -0.14px;
  color: #ffffff;
  margin-top: 4rem;
}
.diagramFlow {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.18);
  box-shadow: inset 0px 0px 34px #ffffff29;
  border-radius: 20px;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 3rem 0rem;
}
.featureIcon {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  width: 8rem;
  height: 8rem;
  padding: 1rem;
}
.featureLogo {
  width: 6rem;
  height: 4rem;
}
.arrowIcon {
  width: 80%;
  object-fit: contain;
  max-width: 20rem;
}
.featureIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.featureLogoIcon {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  width: 8rem;
  height: 8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.featureLogoIcon div {
  text-align: center;
  font-size: 1.5rem;
  color: #522677;
  font-weight: 700;
  letter-spacing: -0.13px;
}
.textFlow {
  margin-top: 1.5rem;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 0rem 0rem;
}
.featureTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textFlow h3 {
  text-align: center;
  font: normal normal bold 1.8rem Noto-Sans-Bold;
  letter-spacing: -0.14px;
  color: #ffffff;
}
.accessContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 6%;
  margin-top: 6rem;
}
.accessContainer h2 {
  font: normal normal bold 3rem Noto-Sans-Bold;
  letter-spacing: -0.26px;
  color: #ffffff;
}
.accessContainer img {
  width: 5rem;
  height: 5rem;
  margin-right: 2.4rem;
}
.crunchImage {
  width: 100%;
  object-fit: contain;
  padding-top: 4rem;
  display: block;
}
/*-----------------------------------------------style discount section */
.discountDesktopWrapper {
  display: block;
  margin-top: 9.4rem;
  width: 100%;
}
.discountMobileWrapper {
  display: none;
}
.discountDesktopContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.discountSectionLine {
  display: block;
  border: 1px solid #707070;
  opacity: 0.62;
  width: 92%;
}
.discountHeading {
  text-align: center;
  font: normal normal 900 4.5rem/6rem Avenir Regular;
  letter-spacing: -0.26px;
  color: #a500ff;
  opacity: 1;
  margin-top: 5.5rem;
}
.discountSubHeading {
  color: var(--unnamed-color-ffffff);
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 2.8rem;
  margin-top: 1rem;
}
.offerImage {
  width: 75%;
  object-fit: contain;
  margin-top: 2.3rem;
  max-width: 1250px;
}

/* ----------------------------------------------style download component */
.downloadContainer {
  display: flex;
  width: 100%;
  background: transparent linear-gradient(#171b29 0%, #3f0055 100%) 0% 0%
    no-repeat padding-box;
}
.downloadLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.downloadText {
  margin-top: 12rem;
}
.downloadLeft h2 {
  text-align: left;
  font: normal normal bold 5.5rem Noto-Sans-Bold;
  letter-spacing: -0.42px;
  color: #ffffff;
}
.storeContainer {
  margin-top: 3rem;
  margin-bottom: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.storeContainer img {
  object-fit: contain;
  width: 100%;
}
.storeContainer a:nth-child(1) {
  margin-right: 2rem;
}
.downloadRight {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.circle {
  position: absolute;
  width: 50rem;
  height: 50rem;
  border-radius: 50%;
  background-color: #ffffff;
  bottom: -20rem;
}
.phoneImage {
  position: absolute;
  z-index: 2;
  object-fit: contain;
  width: 65%;
  bottom: -2rem;
  max-width: 35rem;
}

/* -----------------------------------------------style footer component */
.footerContainer {
  position: relative;
  background: transparent linear-gradient(#b904fc 0%, #3f0055 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: inset 0px -9px 29px #00000029;
  padding: 2.2rem 0rem;
}
.footerLogoContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerLogoContainer img {
  object-fit: contain;
  height: 6rem;
}
.footerNavbar {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footerNavbar ul {
  width: 55%;
  display: flex;
  justify-content: space-between;
}
.underLine {
  width: 55%;
  height: 1px;
  background-color: #ffffff;
  opacity: 0.45;
  margin-top: 1.5rem;
}
.footerNavbar li {
  list-style: none;
  opacity: 0.7;
}
.footerNavbar li:hover {
  opacity: 1;
  font-size: 1.7rem;
}
.footerNavbar a {
  text-decoration: none;
  font: normal normal 600 1.6rem Noto-Sans-SemiBold;
  letter-spacing: 0px;
  color: #ffffffcc;
}
.mediaNavbar {
  margin-top: 1.8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mediaNavbar img {
  width: 2.2rem;
  height: 2.2rem;
  margin: 0rem 3rem;
  opacity: 0.7;
}
.mediaNavbar img:hover {
  opacity: 1;
  width: 2.4rem;
  height: 2.4rem;
}
.copyrightText {
  width: 100%;
  text-align: center;
  margin-top: 1.8rem;
  font: normal normal normal 0.9rem SF Pro Display Bold;
  letter-spacing: 1.89px;
  color: #ffffff;
  opacity: 0.45;
}

@media (max-width: 900px) {
  .downloadButton {
    font-size: 1.8rem;
    padding: 1.4rem 3.5rem;
  }
  .downloadButton:hover {
    font-size: 1.9rem;
  }
  .mainLogo img {
    height: 9rem;
  }
  .navbarContainer {
    height: 14%;
  }
  .navLogo {
    left: -5.5%;
    width: 30%;
  }
  .navLogo h2 {
    font-size: 1.8rem;
    top: 0rem;
  }
  .navLogo p {
    font-size: 1.1rem;
    bottom: 0.6rem;
  }
  .navItems a {
    font-size: 1.7rem;
  }
  .navItems a:hover {
    font-size: 1.8rem;
  }
  .perksContainer {
    bottom: -25%;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 5rem 5rem;
    background: transparent linear-gradient(#0e01139a 0%, #000000 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: none;
  }
  .perkOption {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2rem 0rem;
  }
  .benefitBackground {
    min-height: 70rem;
  }
  .benefitText h2 {
    text-align: center;
    font-size: 4.2rem;
  }
  .benefitText p {
    font-size: 2rem;
    width: 75%;
  }
  .featureContainer {
    padding-top: 25rem;
  }
  .discountHeading {
    font: normal normal 900 4rem/4.2rem Avenir Regular;
    margin-top: 4rem;
  }
  .discountSubHeading {
    font-size: 2.4rem;
  }
  .downloadContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: transparent linear-gradient(#171b29 0%, #3f0055 100%) 0% 0%
      no-repeat padding-box;
    margin-top: -0.1rem;
  }
  .downloadLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .downloadText {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
  }
  .downloadLeft h2 {
    text-align: left;
    font-size: 5rem;
    color: #ffffff;
    margin-left: 1.8rem;
  }
  .downloadRight {
    padding: 22rem 0rem;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .circle {
    width: 44rem;
    height: 44rem;
    bottom: -5rem;
  }
  .phoneImage {
    bottom: -3rem;
    max-width: 35rem;
  }
  .storeContainer {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 700px) {
  .headerContainer {
    height: 65vh;
    background: url("../../../assets/homeBackgroundImage.webp");
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .dropDownNavbar {
    display: block;
  }
  .navbar {
    display: none;
  }
  .mainLogo img {
    height: 8rem;
  }
  .downloadButton:hover {
    font-size: 2.2;
  }
  .featureContainer {
    padding-top: 28rem;
  }
  .perVisitContainer {
    display: flex;
    flex-direction: column;
  }
  .colon {
    display: none;
  }
  .perVisitContainer h2 {
    font-size: 3rem;
  }
  .perVisitContainer img {
    width: 7rem;
    height: 5rem;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .perVisitText {
    padding-left: 0%;
    padding-right: 0%;
    width: 70%;
    text-align: center;
    font-size: 1.6rem;
    margin-top: 1rem;
  }
  .appFlow {
    width: 100%;
  }
  .appFlowContainer {
    display: grid;
  }
  .diagramFlowMobile {
    display: flex;
  }
  .textFlowMobile {
    display: grid;
  }
  .diagramFlow {
    display: none;
  }
  .textFlow {
    display: none;
  }
  .accessContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0%;
    margin-top: 8rem;
  }
  .accessContainer h2 {
    text-align: center;
    font-size: 3rem;
  }
  .accessContainer img {
    width: 7rem;
    height: 7rem;
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
  .footerNavbar ul {
    width: 70%;
  }
  .underLine {
    width: 70%;
  }
  .discountHeading {
    font: normal normal 900 3.3rem/3.8rem Avenir Regular;
    margin-top: 3rem;
  }
  .discountSubHeading {
    font-size: 1.8rem;
  }
}
@media (max-width: 500px) {
  .mainLogo img {
    height: 6.5rem;
  }
  .dropDownMenuIos a {
    font-size: 2.3rem;
  }
  .dropDownMenu a {
    font-size: 2.3rem;
  }
  .downloadButton {
    font-size: 1.6rem;
    padding: 1rem 3rem;
  }
  .downloadButton:hover {
    font-size: 1.7rem;
  }
  .perksContainer {
    bottom: -25%;
    width: 80%;
    padding: 2rem 3rem;
  }
  .discountSectionLine {
    display: block;
    border: 1px solid #707070;
    opacity: 0.62;
    width: 75%;
  }
  .discountHeading {
    font: normal normal 900 2.5rem Avenir Regular;
    margin-top: 1.5rem;
  }
  .discountSubHeading {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
  .offerImage {
    width: 84%;
  }
  .featureContainer {
    padding-bottom: 3rem;
  }
  .perVisitContainer h2 {
    font-size: 3.2rem;
  }
  .perVisitText {
    width: 75%;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
  }
  .perVisitContainer img {
    width: 8rem;
    height: 5rem;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .discountMobileWrapper {
    display: block;
    padding-bottom: 8rem;
  }
  .accessContainer h2 {
    text-align: center;
    font-size: 3.2rem;
  }
  .accessContainer img {
    width: 8rem;
    height: 8rem;
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
  .discountDesktopWrapper {
    display: none;
  }
  .crunchImage {
    display: none;
  }
  .circle {
    width: 40rem;
    height: 40rem;
    bottom: -5rem;
  }
  .phoneImage {
    bottom: -3rem;
    max-width: 35rem;
    height: 35rem;
  }
  .downloadRight {
    padding: 18rem 0rem;
  }
  .storeContainer {
    margin-bottom: 3rem;
  }
  .downloadLeft h2 {
    font-size: 4rem;
    margin-left: 1.1rem;
  }
  .footerNavbar ul {
    width: 80%;
  }
  .underLine {
    width: 80%;
  }
  .textFlowMobile h3 {
    font-size: 2.1rem;
  }
}
@media (max-width: 400px) {
  .mainLogo img {
    height: 7rem;
  }
  .perkOption {
    display: flex;
    align-items: center;
    margin: 2rem 0rem;
  }
  .benefitText h2 {
    text-align: center;
    font-size: 4rem;
  }
  .benefitText p {
    font-size: 2rem;
    width: 95%;
  }
  .circle {
    width: 30rem;
    height: 30rem;
    bottom: -5rem;
  }
  .phoneImage {
    bottom: -3rem;
    max-width: 35rem;
    height: 28rem;
  }
  .downloadRight {
    padding: 13rem 0rem;
  }
  .downloadLeft h2 {
    font-size: 3.4rem;
    margin-left: 1.1rem;
  }
  .footerNavbar ul {
    width: 90%;
  }
  .underLine {
    width: 90%;
  }
  .footerNavbar a {
    font-size: 1.8rem;
  }
  .mediaNavbar img {
    margin: 0rem 3rem;
  }
  .dropDownMenuIos li {
    padding: 3.5rem 0rem;
    padding-left: 5rem;
  }
  .dropDownMenuIos a {
    font-size: 2.4rem;
  }
  .dropDownMenu li {
    padding: 3.5rem 0rem;
    padding-left: 5rem;
  }
  .dropDownMenu a {
    font-size: 2.4rem;
  }
  .storeContainer a:nth-child(1) {
    margin-right: 1rem;
  }
  .storeContainer img {
    width: 86%;
  }
  .storeContainer a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 300px) {
  .mainLogo img {
    height: 6rem;
  }
  .dropDownMenuIos a {
    font-size: 2.2rem;
  }
  .dropDownMenu a {
    font-size: 2.2rem;
  }
  .perkscontainer {
    width: 90%;
  }
  .discountHeading {
    font: normal normal 900 2.2rem Avenir Regular;
    margin-top: 1rem;
  }
  .discountSubHeading {
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  .discountMobileWrapper {
    display: block;
    padding-bottom: 5rem;
  }
  .footerNavbar a {
    font-size: 1.2rem;
  }
  .mediaNavbar img {
    margin: 0rem 1.8rem;
  }
  .diagramContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .textFlowMobile h3 {
    font-size: 2rem;
  }
}
