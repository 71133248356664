.articleContent {
    margin: 4% 9%;
}

.articleHeader {
    margin: 5% 0;

    h3 {
        font-size: 26px;
        line-height: 43px;
        font-weight: 500;
        color: #848484;
    }

    h1 {
        padding: 18px 0;
        font-family: "Avenir Black";
        font-size: 50px;
        font-weight: 900;
        line-height: 62px;
        color: #2C2C2C;
    }

    h4 {
        color: #848484;
        font-size: 26px;
        font-weight: 500;
    }

    @media screen and (max-width: 699px) {
        h3 {
            font-size: 20px;
            font-weight: 500;
            color: #848484;
        }
    
        h1 {
            font-size: 30px;
            font-weight: 900;
            line-height: 42px;
        }
    
        h4 {
            color: #848484;
            font-size: 20px;
            font-weight: 500;
        }
    }
}

.articleBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8%;

    p {
        font-size: 24px;
        line-height: 54px;
        font-weight: 400;
    }

    p:nth-child(2){
        margin-top: 60px;
    }

    img {
        width: 82vw;
        height: 82vh;
        object-fit: cover;
        border-radius: 30px;
        margin: 5% 0;
    }

    @media screen and (max-width: 699px) {
        p {
            font-size: 20px;
            line-height: 40px;
        }
    
        img {
            width: 82vw;
            height: 60vh;
            border-radius: 30px;
        }
    }

    @media screen and (max-width: 480px) {

        img {
            width: 82vw;
            height: 42vh;
        }
    }
}

.breadCrumsContainer {
    font-size: 12px;

    span {
        &:hover {
            cursor: pointer;
            color: #BB68E7;
        }
    }
}

.moreArticleWrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 4% 0;
	gap: 4%;
    width: 80vw;
	justify-content: center;

	@media screen and (max-width:699px) {
		width: 86vw;
	}
}