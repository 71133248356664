.perkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.mainWrapper {
  padding-bottom: 8rem;
  overflow-x: hidden;
}
.perkContent h1 {
  font-family: "Volkhov Regular";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 5.2rem;
  letter-spacing: 2.5px;
}
.perkItems {
  margin-top: 5rem;
}
.perkTextContainer h3 {
  font-family: "Avenir Regular";
  font-weight: 900;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #5e6282;
  letter-spacing: 1.5px;
}
.perkTextContainer h4 {
  font-family: "Avenir Regular";
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #898b97;
  letter-spacing: 1.5px;
}
.perkCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
}
.appDemoContainer {
  position: relative;
}
.appDemoContainer img {
  margin-top: 3rem;
  height: 40rem;
  object-fit: contain;
}
.perkIconContainer img {
  margin-right: 1.5rem;
  width: 3.8rem;
  height: 3.8rem;
}
.discountTicket {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.03),
    0px 15px 34px 0px rgba(0, 0, 0, 0.03), 0px 62px 62px 0px rgba(0, 0, 0, 0.02),
    0px 139px 83px 0px rgba(0, 0, 0, 0.01), 0px 246px 99px 0px rgba(0, 0, 0, 0),
    0px 385px 108px 0px rgba(0, 0, 0, 0), 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  height: 32px;
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
  padding: 1.5rem;
}
.discountTicket h4 {
  font-family: "Avenir Regular";
  font-weight: 350;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #191825;
}
.ticketRight {
  display: none;
}
.ticketBottomLeft {
  display: none;
}
/* --------------------------------------for width upto 700px */
@media screen and (max-width: 768px) {
  .perkContent h1 {
    text-align: center;
  }
  .perkContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
  .ticketBottomLeft {
    display: flex;
  }
  .ticketRight {
    display: flex;
  }
  .ticketBottom {
    display: none;
  }
  .ticketLeft {
    display: none;
  }
  .appDemoContainer {
    margin-bottom: 9.3rem;
  }
  .appDemoContainer img {
    height: 50rem;
  }
  .mainWrapper {
    padding-bottom: 4rem;
  }
}
/* -----------------------------------------------for width upto 500px */
@media screen and (max-width: 500px) {
  .perkCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }
  .perkIconContainer img {
    margin-right: 0rem;
    width: 5.8rem;
    height: 5.8rem;
  }
  .perkTextContainer h3 {
    font-size: 2.1rem;
    line-height: 2.8rem;
    text-align: center;
    margin-top: 1.5rem;
  }
  .perkTextContainer h4 {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 1.5px;
    text-align: center;
    margin-top: 0.5rem;
  }
}
/* --------------------------for width upto 400px */
@media screen and (max-width: 400px) {
  .perkContent h1 {
    font-family: "Volkhov Regular";
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.6rem;
    letter-spacing: 1px;
  }
  .appDemoContainer img {
    height: 40rem;
  }
}
