.fadeLayer {
  position: fixed;
  transition: all 1s ease-in-out;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 100;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.6s ease 0s 1 forwards;
}
@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  50% {
    background: rgba(0, 0, 0, 0.4);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}
