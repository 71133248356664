.qrMobileContainer {
  position: relative;
  background-color: white;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  height: 80%;
  z-index: 0;
  border-radius: 30px;
  overflow: hidden;
  max-height: 520px;
  min-height: 380px;
}
.qrMobileContainer h1 {
  color: #000;
  text-align: center;
  font-family: "Volkhov Regular";
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 5rem;
}
.storeImageContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.storeImageContainer img {
  width: 10.7rem;
  height: 3.5rem;
  object-fit: contain;
}
.qrMobileContent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.backgroundSphere {
  width: 110%;
  height: 40rem;
  object-fit: contain;
  border-radius: 440px;
  background: linear-gradient(180deg, #1a0028 0%, #a700ff 100%);
  position: absolute;
  bottom: -15rem;
  z-index: -5;
}
.phoneScreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}
.centerScreen {
  position: absolute;
  width: 16.9rem;
  height: 33.4rem;
  z-index: 0;
}

.leftScreen {
  position: relative;
  width: 19rem;
  height: 29.1rem;
  z-index: -1;
  right: 1rem;
}
.rightScreen {
  position: relative;
  width: 14.8rem;
  height: 29.1rem;
  z-index: -2;
}

/* -------------------------------------for width above 768px */
@media screen and (min-width: 769px) {
  .qrMobileContainer {
    display: none;
  }
}
/* ----------------------------------------for width below 768px */
@media screen and (max-width: 768px) {
  .qrMobileContainer {
    display: flex;
  }
}

/* ----------------------------------------for width below 600px */
@media screen and (max-width: 600px) {
  .qrMobileContainer h1 {
    font-size: 2.6rem;
    margin-top: 6rem;
  }
  .cancelContainer {
    position: absolute;
    top: 2.5rem;
    right: 4rem;
  }
  .cancelContainer img {
    width: 5rem;
    height: 5rem;
  }
  .backgroundSphere {
    bottom: -12rem;
  }
}
/* ----------------------------------------for width below 500px */
@media screen and (max-width: 500px) {
  .cancelContainer {
    position: absolute;
    top: 2.5rem;
    right: 3rem;
  }
  .cancelContainer img {
    width: 4rem;
    height: 4rem;
  }
  .backgroundSphere {
    bottom: -9rem;
  }
  .centerScreen {
    position: absolute;
    width: 12rem;
    height: 28.4rem;
    z-index: 0;
  }
  .leftScreen {
    position: relative;
    width: 16rem;
    height: 29.1rem;
    z-index: -1;
    left: 1rem;
  }
  .rightScreen {
    position: relative;
    width: 14.8rem;
    height: 29.1rem;
    z-index: -2;
  }
}
/* ----------------------------------------for width below 400px */
@media screen and (max-width: 400px) {
  .qrMobileContainer {
    height: 48%;
  }
  .phoneScreenContainer {
    margin-bottom: 6rem;
  }
}
/* ----------------------------------for width upto 300px */
@media screen and (max-width: 300px) {
  .qrMobileContainer {
    height: 40%;
  }
}
