.downloadComponentContent {
  min-height: 100vh;
  background: linear-gradient(#161b2a, #5e0479);
  padding: 2rem 0rem;
  display: grid;
  grid-template-columns: 50% 50%;
}
.downloadComponentLeft {
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;
}
.downloadComponentLeft h2 {
  color: white;
  font-size: 5.5rem;
  line-height: 6.5rem;
}
.downloadComponentRight {
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadComponentStoreContainer {
  display: flex;
  margin-top: 4rem;
  margin-left: 1rem;
}
.downloadComponentStoreContainer img {
  margin-left: 1rem;
  object-fit: contain;
}
@media (max-width: 768px) {
  .downloadComponentContent {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }
  .downloadComponentLeft {
    padding-left: 0;
    align-items: center;
  }
  .downloadComponentStoreContainer {
    justify-content: space-between;
    margin-left: 0rem;
  }
  .downloadComponentStoreContainer img {
    margin: 0rem 0.5rem;
    width: 92%;
  }
}
