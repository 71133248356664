.downloadPageContainer {
  min-height: 100vh;
  background: linear-gradient(#161b2a, #5e0479);
}
.downloadPageLogoContainer {
  width: 100%;
  padding: 2rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadPageLogoContainer img {
  object-fit: contain;
  height: 5rem;
}
.downloadPageContent {
  display: grid;
  grid-template-columns: 50% 50%;
}
.downloadPageLeft {
  padding: 6rem 0rem;
  padding-left: 4rem;
}
.downloadPageLeft h1 {
  color: white;
  font-size: 4rem;
  font-family: "Brittany Signature";
  line-height: 11rem;
  letter-spacing: -0.42px;
  font-weight: 100;
}
.downloadPageLeft h2 {
  color: white;
  font-size: 3.5rem;
  font-family: "Noto-Sans-Bold";
  line-height: 4.8rem;
}
.downloadPageStoreContainer {
  padding: 4rem 0rem;
  display: flex;
  justify-content: center;
}
.downloadPageStoreContainer img {
  object-fit: contain;
  margin: 0rem 1rem;
}
.downloadPageRight {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
.downloadPageCircle {
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  background-color: white;
  display: block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadPageCircle img {
  position: absolute;
  object-fit: contain;
  width: 31rem;
  bottom: -2rem;
}
@media (max-width: 768px) {
  .downloadPageContent {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 500px) {
  .downloadPageLeft {
    padding-left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .downloadPageStoreContainer {
    display: flex;
    justify-content: space-between;
  }
  .downloadPageStoreContainer img {
    margin: 0rem 0.5rem;
    width: 95%;
  }
  .downloadPageCircle {
    width: 35rem;
    height: 35rem;
  }
  .downloadPageCircle img {
    width: 27rem;
  }
}
@media (max-width: 400px) {
  .downloadPageLeft h2 {
    font-size: 3rem;
  }
  .downloadPageCircle {
    width: 35rem;
    height: 35rem;
  }
}
@media (max-width: 300px) {
  .downloadPageLeft h2 {
    font-size: 2.5rem;
    line-height: 4rem;
  }
  .downloadPageCircle {
    width: 28rem;
    height: 28rem;
  }
  .downloadPageCircle img {
    width: 20rem;
  }
}
