.animationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16rem 0rem;
}
.doubleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
}
.animationContainer p {
  font-family: "Avenir Regular";
  font-size: 3.7rem;
  line-height: 5.4rem;
  color: "black";
  font-weight: bold;
  margin-right: 0.8rem;
}
.animationWrapper {
  display: inline-block;
  overflow: hidden;
  height: 3.8rem;
  text-align: left;
  overflow-x: hidden;
}
.animatedList {
  transition: "transform 0.2s ease";
}
.animatedText {
  padding: 0.1rem 0rem;
  font-family: "Avenir Regular";
  font-weight: 900;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #7404b9;
}
/* -------------------------------------------------for width upto 768px */
@media screen and (max-width: 768px) {
  .doubleWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  .animationContainer p {
    margin-right: 0rem;
  }
}
/* -------------------------------------------------for width upto 600px */
@media screen and (max-width: 600px) {
  .animationContainer p {
    font-size: 3rem;
    line-height: 4.8rem;
  }
  .animatedText {
    padding: 0.1rem 0rem;
    font-family: "Avenir Regular";
    font-weight: 900;
    font-size: 3rem;
    line-height: 3.4rem;
    color: #7404b9;
    margin-top: 0.12rem;
  }
}
